import React,  { useState, useEffect, memo }  from 'react';
import { createBrowserHistory } from "history";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {getCEP }  from '../../utils/utils';
import {addEndereco, updateEndereco, deleteEndereco}   from '../../utils/databaseFireBase'
//import {addEndereco, updateEndereco, deleteEndereco}   from '../../utils/databaseFBAPI'
//import {addEndereco, updateEndereco, deleteEndereco}   from '../../utils/databaseSQL'
import 'bootstrap/dist/css/bootstrap.min.css';
import './EnderecoModal.css';
import { useEnderecoStore, useConfirmacaoStore} from '../../store'
import { useSnackbar } from 'notistack';
import Confirmacao from '../Confirmacao';

const EnderecoModal = (props) => {
  console.log('Render Modal')

  const db_name = 'endereco'
    
  const [inputs, setInputs] = useState({});
  const [erros,setErros] = useState([]);

  const openConfirmacao = useConfirmacaoStore((state) => state.openConfirmacao);
  const executeAction = useConfirmacaoStore((state) => state.executeAction);
  const enderecos = useEnderecoStore((state) => state.enderecos);
 

  const { enqueueSnackbar} = useSnackbar();


  let history = createBrowserHistory();
  history.listen(({ location, action }) => {
  if (action === "POP") {
        props.handleClose()
   }  
  });

  useEffect(() => {
    
    if (props.action !== 'add' && props.endereco) {
       setInputs(props.endereco)
    }
 }, []);

 useEffect(() => {
    if (executeAction  ) {
       handleAction();
    }
}, [executeAction]);

useEffect(() => {
  console.log('OpenConfirmacao')
}, [openConfirmacao]);


const  addEnderecoList =   (endereco) => {

  const erro = verificaErro(endereco)
  if (!erro) {
    const newenderecos = [...enderecos, endereco];
    props.handleClose();
    useEnderecoStore.setState({enderecos: [...newenderecos], enderecosAux: [...newenderecos]})
  }else{
    props.handleClose(); 
  }    
}

const updateEnderecoList = (data, id) => {

  const erro = verificaErro(data)
  if (!erro) {
    const endsAux = [...enderecos]
    const objIndex = endsAux.findIndex((obj => obj.id == id));
    endsAux[objIndex].local = inputs.local
    endsAux[objIndex].cep = inputs.cep
    endsAux[objIndex].endereco = inputs.endereco
    endsAux[objIndex].complemento = inputs.complemento
    endsAux[objIndex].bairro = inputs.bairro
    endsAux[objIndex].estado = inputs.estado
    endsAux[objIndex].cidade = inputs.cidade
    endsAux[objIndex].userid = props.userid
    useEnderecoStore.setState({enderecos: [...endsAux], enderecosAux: [...endsAux]})
    props.handleClose();
  }else{
    props.handleClose();
  }  
  
}

const  removeEnderecoList =  (data, id) => {
  const erro = verificaErro(data)
  if (!erro) {
     const endAux = enderecos.filter(end => end.id != id);
     useEnderecoStore.setState({enderecos: [...endAux], enderecosAux: [...endAux]})
     props.handleClose();
  }else{
    props.handleClose();
  }   
}
 
  const handleChange = (event) => {
    const nome = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [nome]: value}))
  };

  const handleCEP =  (event) => {
    const cep = event.target.value;
    
   
    if (cep === null || cep ===  '' || cep.length !== 8) {
      setErros({'cep': 'Cep em branco ou inválido'})
    }else {
        setErros([]);  
        getCEP(cep).then((dataCEP) => {
            if (dataCEP) {          
              setInputs(values => ({...values,
                ['endereco'] : dataCEP.logradouro,
                ['bairro'] : dataCEP.bairro,
                ['estado'] : dataCEP.uf,
                ['cidade'] : dataCEP.localidade
                }));
            }else{
              setErros({'cep': 'Cep inválido ou inexistente'})
            }  
        })
    } 
  };

  const findFormErrors = () => {
    const { local, cep, endereco, complemento, bairro, cidade, estado} = inputs;
    const newErros = {}
    const ufs = ['AC','AL','AM','AP','BA','CE','ES','GO',
                 'MA','MG','MS','MT','PA','PB','PE','PI',
                 'PR','RJ','RN','RO','RR','RS','SC','SE',
                 'SP','TO']

    
    if ( !local || local === '' ) newErros.local = 'Campo Obrigatório!'
    if ( !cep || cep === '' ) newErros.cep = 'Campo Obrigatório!'
    if ( cep && cep.toString().length !== 8 ) newErros.cep = 'CEP deve ter 8 posições!'
    if ( !endereco || endereco === '' ) newErros.endereco = 'Campo Obrigatório!'
    if ( !complemento || complemento === '' ) newErros.complemento = 'Campo Obrigatório!'
    if ( !bairro || bairro === '' ) newErros.bairro = 'Campo Obrigatório!'
    if ( !cidade || cidade === '' ) newErros.cidade = 'Campo Obrigatório!'
    if ( !estado || estado === '' ) newErros.estado = 'Campo Obrigatório!'
    if ( estado && estado.length !== 2 ) newErros.estado = 'UF deve ter 2 posições!'
    if ( estado && !ufs.includes(estado)) newErros.estado = 'UF inválida!'
    
    return newErros
   
  }
  
  const verificaErro = (data) => {

    if (typeof(data) === 'string' && !data.includes('erro')) {
       useConfirmacaoStore.setState({ executeAction: false, openConfirmacao: false });
       return false 
    }

    if ('erro' in  data) {
      enqueueSnackbar('Erro no tratamento de endereços', { variant: "error" })
      return true  
    }else {
      useConfirmacaoStore.setState({ executeAction: false, openConfirmacao: false });
      return false 
     }
  };

  const handleAction = () => {

    switch (props.action) {
      case "add":
        addEndereco(db_name, inputs, props.userid ).then((data) => {addEnderecoList(data)});
        break
      case "update":
        updateEndereco(db_name,props.endereco.id, inputs, props.userid).then((data) => {updateEnderecoList(data, props.endereco.id)});
        break
      case "delete":
         deleteEndereco(db_name, props.endereco.id)
           .then((data) => {removeEnderecoList( data, props.endereco.id)}); 
 
      useConfirmacaoStore.setState({ executeAction: false, openConfirmacao: false });
    };
  }  


  const handleSubmit = (event) => {
    event.preventDefault();
    const newErros = findFormErrors();
    if ( Object.keys(newErros).length > 0 ) {
      setErros(newErros)
    }else{
       useConfirmacaoStore.setState({openConfirmacao: true });
    }
  };
   
  
  return (
    <React.Fragment>
     <Form >
     <Form.Group controlId="formLocal">
       <Form.Label>Local</Form.Label>
       <Form.Control
         type="text"
         name="local"
         required
         value={inputs.local ||  ""} 
         onChange={handleChange}
         isInvalid={!!erros.local }
         disabled ={props.action === "delete"}
        />
       <Form.Control.Feedback type='invalid'>
        { erros.local }
       </Form.Control.Feedback> 
     </Form.Group>

     <Form.Group controlId="formCEP">
       <Form.Label>CEP</Form.Label>
       <Form.Control
         type="text"
         name="cep" 
         required
         value={inputs.cep || ""}
         placeholder="Digite o CEP para preenchimento automático" 
         onChange={handleChange} 
         onBlur={handleCEP} 
         isInvalid={!!erros.cep }
         disabled ={props.action === "delete"}  />
        <Form.Control.Feedback type='invalid'>
           { erros.cep }
        </Form.Control.Feedback>   
     </Form.Group>

     <Form.Group controlId="formEndereco">
       <Form.Label>Endereco</Form.Label>
       <Form.Control
         type="text"
         name="endereco"
         required
         value={inputs.endereco || ""} 
         onChange={handleChange}
         isInvalid={!!erros.endereco } 
         disabled ={props.action === "delete"} />
        <Form.Control.Feedback type='invalid'>
           { erros.endereco }
        </Form.Control.Feedback>    
     </Form.Group> 

     <Form.Group controlId="formComplemento">
       <Form.Label>Complemento</Form.Label>
       <Form.Control
         type="text"
         name="complemento"
         required
         value={inputs.complemento || ""} 
         onChange={handleChange}
         isInvalid={!!erros.complemento } 
         disabled ={props.action === "delete"}  />
        <Form.Control.Feedback type='invalid'>
           { erros.complemento }
        </Form.Control.Feedback>    
     </Form.Group> 
    
     <Form.Group controlId="formBairro">
       <Form.Label>Bairro</Form.Label>
       <Form.Control
         type="text"
         name="bairro"
         required
         value={inputs.bairro || ""} 
         onChange={handleChange}
         isInvalid={!!erros.bairro }
         disabled ={props.action === "delete"}  />
        <Form.Control.Feedback type='invalid'>
           { erros.bairro }
        </Form.Control.Feedback>  
     </Form.Group>

     <Form.Group controlId="formCidade">
       <Form.Label>Cidade</Form.Label>
       <Form.Control
         type="text"
         name="cidade"
         required
         value={inputs.cidade || ""} 
         onChange={handleChange}
         isInvalid={!!erros.cidade }
         disabled ={props.action === "delete"}  />
        <Form.Control.Feedback type='invalid'>
           { erros.cidade }
        </Form.Control.Feedback>    
     </Form.Group> 
     
     <Form.Group controlId="formEstado">
       <Form.Label>Estado</Form.Label>
       <Form.Control
         type="text"
         name="estado"
         required
         value={inputs.estado || ""} 
         isInvalid={ !!erros.estado }
         disabled ={props.action === "delete"}  
         onChange={handleChange}  />
        <Form.Control.Feedback type='invalid'>
           { erros.estado }
        </Form.Control.Feedback>    
     </Form.Group>
     <div><p/></div>
     <Row className="justify-content-end">
        <Col xs={8}>
        </Col>
        <Col xs={2}>
          <Button variant='secondary'  onClick={props.handleClose}  >
              Fechar
          </Button>
        </Col>
        <Col xs={2}  >
           <Button variant='primary'  onClick={handleSubmit}>
              Enviar
           </Button>
        </Col>
     </Row>
   </Form>
   <Confirmacao 
      action = {props.action}
    /> 
   </React.Fragment>


  );
};

export default memo(EnderecoModal);

