import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { SnackbarProvider } from 'notistack';
import  AuthProvider  from './context';

import  App from './App';
import  EnderecosListMUI from './pages/EnderecosListMUI';
import  SignUp from './pages/SignUp';



import 'regenerator-runtime/runtime'

//if (process.env.NODE_ENV === 'development') {
//  const { worker } = require('./mocks/browser')
//  worker.start()
//}

const history = createBrowserHistory();
 
ReactDOM.render(
    <BrowserRouter >
        <SnackbarProvider  maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          autoHideDuration={5000}>
          <AuthProvider>
            <Routes  >
                <Route exact path="/" element={<SignUp />} />
                <Route exact path="/endereco" element={<EnderecosListMUI />} />
            </Routes>
         </AuthProvider>
        </SnackbarProvider>  
    </BrowserRouter>,
    document.getElementById('root')
  )
  



