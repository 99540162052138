import React from 'react'
import {useState, useEffect, memo}  from 'react';
import Grid  from '@material-ui/core/Grid';
import Box  from '@material-ui/core/Box';
import Select  from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@material-ui/core/IconButton';


import {useEnderecoStore} from '../store'

const Filter = (props) => {
    console.log('render Filter')

    const [ select, setSelect ] = useState('');
    const [ selectValue, setSelectValue ] = useState('');
    const [ selectOptions, setSelectOptions ] = useState([]);
    const [ selectType, setSelectType ] = useState("text"); 

    const enderecos = useEnderecoStore((state) => state.enderecos);
           
    
useEffect(() => {
    if (select) {
       const filterObj = props.selectFields.filter((e) => e.value === select)[0]
       setSelectType(filterObj.type)
       if (filterObj.type === 'text') {
          setSelectValue("")
       }else{
          setSelectValue("")
          setSelectOptions(filterObj.options) 
       }
    }else {
      setSelectValue("")
    }
 }, [select]);



const handleSelectChange = (event) => {
  
    const target = event.target;
    const value = target.value;

    setSelect(value)
  }

 const handleSelectValueChange = (event) => {

    const target = event.target;
    const value = target.value;
    
    setSelectValue(value)
  }

 const handleSearchClick = (event) => {
    let aux = []
    if (select && selectValue) {
      const filterObj = props.selectFields.filter((e) => e.value === select)[0]
      const index = filterObj.value
      aux = enderecos.filter(end => end[index].match(new RegExp('^' + selectValue))); 
      useEnderecoStore.setState({ enderecosAux: [...aux]})
    } else {
      useEnderecoStore.setState({enderecosAux: [...enderecos]})
    }
  }

return (

      <>
      <Select
        labelId="select"
        id="select"
        value={select}
        onChange={handleSelectChange}
        variant="outlined"
      >
        {props.selectFields.map((f, index) => ( 
           <MenuItem key={index} value={f.value}>{f.label}</MenuItem>
        ))}
      </Select>
      {selectType !== 'select' &&
      <TextField
          id="selectValue"
          value={selectValue}
          variant="outlined"
          onChange={handleSelectValueChange}
          style = {{width: 500}}
          InputProps={{
             endAdornment: (
               <IconButton onClick={() => {handleSearchClick()}}>
                 <SearchIcon  color='primary'/>
               </IconButton>
             ),
          }} />}
      {selectType === 'select' &&
      <TextField
          select 
          id="selectValue"
          value={selectValue}
          variant="outlined"
          onChange={handleSelectValueChange}
          style = {{width: 500}}
          InputProps={{
             endAdornment: (
               <IconButton onClick={() => {handleSearchClick()}} >
                 <SearchIcon  color='primary' />
               </IconButton>
             ),
          }} >
          {selectOptions?.map((f, index) => ( 
             <MenuItem key={index} value={f}>{f} </MenuItem>
          ))}

          </TextField> } 


    </>
    )
} 

export default memo(Filter)