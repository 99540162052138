import  Modal from 'react-bootstrap/Modal';
import  EnderecoModal from './EnderecoModal';
import {useEnderecoStore} from '../../store'


export default function ModalCall (props) {

const show =  useEnderecoStore((state) => state.show)

const handleClose =  () => {useEnderecoStore.setState({show: false })};

return (    
    <>
        <Modal show={show} onHide={handleClose} > 
        <Modal.Header closeButton >
            <Modal.Title >{props.nome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <EnderecoModal userid= {props.userid} action={props.action} endereco={props.endereco} handleClose={handleClose} />
        </Modal.Body>
        </Modal>
    </>    
    );
}