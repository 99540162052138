import { memo} from 'react';
//import { useNavigate } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {useConfirmacaoStore} from '../store'



const Confirmacao = (props) => {

  console.log('Render Confirmacao')
  
  const {action} = props;
  const openConfirmacao = useConfirmacaoStore((state) => state.openConfirmacao);
 

  let title = ''
  let msg = ''

  switch (action) {
  
    case "add":
      title = 'Inclusão de Endereço'
      msg = 'Confirma a Inclusão de Endereço?'
      break
    case "update":
      title = 'Alteração de Endereço'
      msg = 'Confirma a Alteração de Endereço?'
      break
    case "delete":
      title = 'Exclusão de Endereço'
      msg = 'Confirma a Exclusão de Endereço?'

    }

  
  const handleClose = (event) => {
     useConfirmacaoStore.setState({ openConfirmacao: false, executeAction: false })
  }

  const handleAction = (event) => {
    useConfirmacaoStore.setState({ openConfirmacao: false,  executeAction:true })
  }


    return (
      <Dialog open={openConfirmacao}  >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  onClick={() => handleClose()} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button   onClick={() =>handleAction ()} variant="contained" color="primary" autoFocus="autoFocus">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    )
  }
export default memo(Confirmacao); 