import create from 'zustand'

const useStore = create(set => ({
    isLoading: true,
    show: false,
    refresh: false,
    enderecos: [],
    enderecosAux: []
}))

  export const useEnderecoStore = useStore; 

  const useStore2 = create(set => ({
    openConfirmacao : false,
    executeAction : false
}))

  export const useConfirmacaoStore = useStore2; 


  


  