import React, { useEffect, useState } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from '../firebase';
 


export const AuthContext = React.createContext();

export const  AuthProvider = ({ children }) => {
  const [userid, setUserId] = useState();
  const [nome, setNome] = useState();
  const values = { userid, setUserId, nome, setNome}


  return (
    <AuthContext.Provider value={values}>
      {children}
      <div className='firebaseui-auth-container'></div>
    </AuthContext.Provider>
  );
};

export default AuthProvider






