
export const getCEP =  async (cep) => {
     
    let url="https://viacep.com.br/ws/" + cep + "/json/";
    let cepData;
    

    await fetch(url)  
        .then(response => {
          if (!response.ok) {
            throw new Error('Erro no fetch');
          }
          return response.json();
        })
        .then(myJson => {
         if (myJson.erro)  {
            throw new Error('CEP inválido ou inexistente');
         } 
         cepData = myJson;
         return cepData;  
         })
       .catch(error => {
         return error; 
       })

       return cepData;
   };
  

 
  
  
  
  
  


  





   
