  import React, { useContext } from 'react';
  import { useNavigate } from "react-router-dom";
  import { makeStyles } from '@material-ui/core/styles';
  import AppBar from '@material-ui/core/AppBar';
  import Toolbar from '@material-ui/core/Toolbar';
  import Typography from '@material-ui/core/Typography';
  import IconButton from '@material-ui/core/IconButton';
  import MenuIcon from '@material-ui/icons/Menu';
  import ExitToAppIcon from '@material-ui/icons/ExitToApp';

  import {AuthContext}  from '../context';
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/auth';
  import { firebaseConfig } from '../firebase';
     
  const firebaseApp = firebase.initializeApp(firebaseConfig);  
  
  const useStyles = makeStyles(theme => ({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));
  
  const Navbar = (props) => {
    const classes = useStyles();
    const {userid, setUserId}  = useContext(AuthContext) 

    let navigate = useNavigate()
    
    const signOutClick = () => {
  
      console.log('SignOut')
      firebaseApp.auth().signOut()
          .then(() => sessionStorage.removeItem("userid"))
          .then(() => setUserId())
          .then(() => navigate('/'))
    }
  
  
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}>
              <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Lupa Melo Informática
          </Typography>
          <Typography variant="body1" style={{marginLeft: 10, padding: 4}} >
              {props.nome}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={signOutClick}>

              <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };
  
  export default Navbar;