import React from 'react'
import { useRef, useEffect, useCallback, memo, useContext}  from 'react';
import { useNavigate } from "react-router-dom";

import {ListItemText, ListItem, ListItemIcon, List} from '@material-ui/core';
import {Delete as DeleteIcon, Edit as EditIcon, Add as AddIcon, Refresh as RefreshIcon} from "@material-ui/icons";
import {Button, Grid, Box, Typography, CircularProgress}   from '@material-ui/core';

import { useSnackbar } from 'notistack';

import {SelectFields} from './assets/select-filter.js'
import Filter from '../components/Filter';
import Navbar from '../components/Navbar';
import  ModalCall from './modal/Modal';
import { useEnderecoStore} from '../store'
import {getEnderecosDb} from '../utils/databaseFireBase'
//import {getEnderecosDb} from '../utils/databaseFBAPI'
//import {getEnderecosDb} from '../utils/databaseSQL'


const EnderecosListMUI = () =>  {

   let navigate = useNavigate()

  
   const { enqueueSnackbar} = useSnackbar();

   const db_name = 'endereco'

   let userid = useRef()
   let nome = useRef()
   
       
   const action = useRef("add")
   const endereco = useRef({})
   const enderecos = useEnderecoStore((state) => state.enderecos);
   const enderecosAux =  useEnderecoStore((state) => state.enderecosAux)
   const isLoading =  useEnderecoStore((state) => state.isLoading)
   const refresh =  useEnderecoStore((state) => state.refresh)
   const show =  useEnderecoStore((state) => state.show)

   
   console.log('RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR ' + userid.current)
  
   
   const montaLista = useCallback(() => {
      let endList = []
      getEnderecosDb(db_name, userid.current).then((data) => {endList = [...data]}).then (() => {
           
      if (endList.length === 1 && endList[0] ['Erro'] ) {
         enqueueSnackbar('Erro na obtenção de endereços', { variant: "error" })
         useEnderecoStore.setState({isLoading: false})
      }else{   
         if (endList.length === 0)  {
            enqueueSnackbar('Nenhum endereço cadastrado! Cadastre novo endereço', { variant: "warning" })
            useEnderecoStore.setState({isLoading: false})
         }else{
            useEnderecoStore.setState({enderecos:[...endList], enderecosAux:[...endList], isLoading: false})
         }
      }

      })
    }) 

    
   useEffect(() => {
      const storage  =   sessionStorage.getItem("userid");

      if (storage) {
         const strs = storage.split(',')
         if (strs) {
            userid.current = strs[0]
            nome.current = strs[1].split(' ').slice(0, -1).join(' ');
         }
      }

     if (!userid.current) {
        //enqueueSnackbar('Usuário não logado', { variant: "warning" })
        navigate('/')
     }else{    
        montaLista() 
     } 
     
   }, []);


   useEffect(() => {
      console.log('show')
   }, [show]);

   
   useEffect(() => {
      console.log('endereco')
   }, [enderecos]);
  
   useEffect(() => {
      console.log('enderecoAux')
   }, [enderecosAux]);

   useEffect(() => {
      console.log('isloading')
   }, [isLoading]);

   useEffect(() => {
      console.log('refresh')
   }, [refresh]);

   const handleRefreshClick = () => {
      montaLista()
   }

   const handleUpdateClick =(end) => {
      action.current = 'update'
      endereco.current = end
      useEnderecoStore.setState({show: true})
   }

   const handleDeleteClick = (end) => {
      action.current = 'delete'
      endereco.current = end
      useEnderecoStore.setState({show: true})
   }

   
   const handleAddEndereco = () => {
      action.current = 'add'
      useEnderecoStore.setState({show: true})
   }

   return (
      <div  style={{ display: 'block', padding: 10 }}>
      <Grid  align="left"   >
         <Navbar nome={nome.current} />
      </Grid>   
    
      <Box display="flex" align="center" style = {{height: 55, marginTop: 15}} justifyContent="center"  >
            <Filter
               id="idFilter"
               selectFields = {SelectFields}
            />
            <Button
               variant="contained"
               color="primary"
               size= "large"
               startIcon={<AddIcon />}
               style = {{marginLeft:15, height: 50, marginTop: 1}}
               onClick={handleAddEndereco}
               className = 'filterButton'
               >Add
            </Button>
      </Box> 

      <Grid container display="flex" spacing={0} align="center" justifyContent="center"  >
         {isLoading && <CircularProgress/>}
            <Grid container item xs={12} justifyContent="center" >
               <Typography variant="h4">Endereços</Typography>
               <Button  aria-label="Refresh" onClick={() => {handleRefreshClick()}}>
                     <RefreshIcon size='small' color='primary'/>
               </Button> 
            </Grid>
            <Grid item xs={12} md={8} >
               <List >
                  {enderecosAux?.map(end => (
                  <div key={end.id}>   
                     <ListItem  >
                        <ListItemText  id={"end_" + end.id} value={end.id} primary={" " + end.endereco + " " + end.complemento } 
                                    secondary={end.bairro + " " + end.cidade + " " + end.estado + " " + end.cep} >
                        </ListItemText>
                        <Box m={1}
                              //margin
                           display="flex"
                           justifyContent="flex-end"
                           alignItems="center">
                        <ListItemIcon    >
                           <Button   aria-label="Update" onClick= {() => {handleUpdateClick(end)}} >
                              <EditIcon size='small' color='primary'/>
                           </Button>                               </ListItemIcon>
                        <ListItemIcon >
                              <Button  onClick={() => {handleDeleteClick(end)}}  aria-label="Delete">
                              <DeleteIcon size='small' color='primary'/>
                           </Button>
                        </ListItemIcon>
                        </Box>
                     </ListItem>
                  </div>
                  ))}
               </List>   
            </Grid>      
      </Grid>
      
      <ModalCall nome='Endereco'
          userid = {userid.current}
          action = {action.current}
          endereco = {endereco.current}
      />
      </div>
      

      
      
   )

};

export default memo(EnderecosListMUI);

