import { db} from '../firebase';
import {  collection,  doc, addDoc, updateDoc, deleteDoc, query, where, getDocs } from 'firebase/firestore';

  
  export const addEndereco = async (db_name, inputs, userid) => {
    
    const endRef = collection(db, db_name);

    const payload = JSON.parse(JSON.stringify(inputs));
    payload['userid'] = userid
   
    const res = await addDoc(endRef, payload);

    inputs.id = res.id;
  
    return inputs;
  
  }
  
  export const updateEndereco = async (db_name, id, inputs) => {
    
    const endRef = doc(db, db_name, id);
       
    await updateDoc(endRef, inputs)
      
    return inputs;
  
  } 

  export const deleteEndereco = async (db_name, id) => {
    try {
    
    const endRef = doc(db, db_name, id);
    
    await deleteDoc(endRef)

    return 'Endereco excluido com sucesso'

    }catch (error) {
       return {'erro': 'Erro na exclusãp de endereços'}
    }  

  }

   export const  getEnderecosDb = async (db_name, userid) => {
      try {
          console.log('DDDDDDDDDDDDDDDDDDDDDDDDDDD')
          const endRef = collection(db, db_name);
          const q = query(endRef, where("userid", "==", userid));
          const endSnapshot = await getDocs(q);
          const endList = endSnapshot.docs.map(end => ({id: end.id, ...end.data()}));
          return endList
          
        } catch (error) {
            return [{'Erro': 'Erro na obtenção de endereços'}]
            
        }
  }
  
 





   
