import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseConfig } from '../firebase';
import {AuthContext}  from '../context';



firebase.initializeApp(firebaseConfig);

const SignUp = () => {

  let navigate = useNavigate();
  
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/endereco',
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
//      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.

      signInSuccessWithAuthResult: () => {
        return false
      }
    }  
  };

     const {userid, setUserId}  = useContext(AuthContext) 
    
     const atualizaUser = (u) =>setUserId(u)

      useEffect(() => {
   
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(function(user)  {
              if (user) {
                  atualizaUser(user.uid)
                  sessionStorage.setItem("userid", user.uid + ',' + user.displayName );
                  navigate('/endereco')
                }   
              });
              return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
      },[userid]);


    return (
      <>
        <div className='container'>
              <h3>Please sign-in:</h3>
              <StyledFirebaseAuth  uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        </div>
       
      </> 
    )
}        

export default SignUp
 